import React from 'react';
import { Modal, Box, Button } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface DynamicModalProps {
    open: boolean;
    modalTitle: string;
    modalContent: string | null;
    handleCloseModal: () => void;
}

const DynamicModal: React.FC<DynamicModalProps> = ({ open, modalTitle, modalContent, handleCloseModal }) => {
    return (
        <Modal open={open} onClose={handleCloseModal}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 'auto',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    textAlign: 'center'
                }}
            >
                <h3
                    id="dynamic-modal-title"
                    style={{ color: modalTitle === "Error Details" ? 'red' : 'inherit' }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {modalTitle === "Error Details" && <WarningAmberIcon sx={{ color: '#FF0000', mr: 1 }} />}
                        {modalTitle}
                    </Box>
                </h3>

                <p
                    id="dynamic-modal-description"
                    style={{ color: modalTitle === "Error Details" ? 'red' : 'inherit' }}
                >
                    {modalContent}
                </p>
                <Button
                    variant="contained"
                    onClick={handleCloseModal}
                >
                    Close
                </Button>
            </Box>
        </Modal>
    );
};

export default DynamicModal;
