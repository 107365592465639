import React from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel,
    Backdrop,
    CircularProgress,
    Box,
    Typography
} from '@mui/material';
import TableLoader from '../GlobalComponents/TableLoader';

interface SortableTableProps {
    rows: any[];
    orderBy: string | undefined;
    order: 'ASC' | 'DESC' | undefined;
    handleRequestSort: (property: string) => void;
    tableTotal: any;
    btc: number;
    isLoading: boolean;
}

const SortableTable: React.FC<SortableTableProps> = ({ rows, orderBy, order, handleRequestSort, tableTotal, btc, isLoading }) => {
    return (
        <TableContainer component={Paper}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                        <TableCell sx={{ fontSize: '0.8rem', backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#51515107' : '#f5f5f5', }} className="bg-gray-100 dark:bg-slate-800">
                            <TableSortLabel
                                active={orderBy === 'expenseDate'}
                                onClick={() => handleRequestSort('expenseDate')}
                                sx={{
                                    '& .MuiTableSortLabel-icon': {
                                        transform: orderBy === 'expenseDate' && order === 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.3s ease',
                                    },
                                }}
                            >
                                Date
                            </TableSortLabel>
                        </TableCell>

                        <TableCell sx={{fontSize: '0.8rem', backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#51515107' : '#f5f5f5',}} className="bg-gray-100 dark:bg-slate-800">
                            <TableSortLabel
                                active={orderBy === 'workerId'}
                                onClick={() => handleRequestSort('workerId')}
                                sx={{
                                    '& .MuiTableSortLabel-icon': {
                                        transform: orderBy === 'workerId' && order === 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.3s ease',
                                    },
                                }}
                            >
                                Worker ID
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{fontSize: '0.8rem', backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#51515107' : '#f5f5f5',}} className="bg-gray-100 dark:bg-slate-800">
                            <TableSortLabel
                                active={orderBy === 'customerId'}
                                onClick={() => handleRequestSort('customerId')}
                                sx={{
                                    '& .MuiTableSortLabel-icon': {
                                        transform: orderBy === 'customerId' && order === 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.3s ease',
                                    },
                                }}
                            >
                                Customer
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{fontSize: '0.8rem', backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#51515107' : '#f5f5f5',}} className="bg-gray-100 dark:bg-slate-800">
                            <TableSortLabel
                                active={orderBy === 'machineModelName'}
                                onClick={() => handleRequestSort('machineModelName')}
                                sx={{
                                    '& .MuiTableSortLabel-icon': {
                                        transform: orderBy === 'machineModelName' && order === 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.3s ease',
                                    },
                                }}
                            >
                                Machine Model
                            </TableSortLabel>
                        </TableCell>

                        <TableCell sx={{fontSize: '0.8rem', backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#51515107' : '#f5f5f5',}} className="bg-gray-100 dark:bg-slate-800">
                            <TableSortLabel
                                active={orderBy === 'currency'}
                                onClick={() => handleRequestSort('currency')}
                                sx={{
                                    '& .MuiTableSortLabel-icon': {
                                        transform: orderBy === 'currency' && order === 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.3s ease',
                                    },
                                }}
                            >
                                Coin
                            </TableSortLabel>
                        </TableCell>

                        <TableCell sx={{fontSize: '0.8rem', backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#51515107' : '#f5f5f5',}} className="bg-gray-100 dark:bg-slate-800">
                            <TableSortLabel
                                active={orderBy === 'uptimeInHours'}
                                onClick={() => handleRequestSort('uptimeInHours')}
                                sx={{
                                    '& .MuiTableSortLabel-icon': {
                                        transform: orderBy === 'uptimeInHours' && order === 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.3s ease',
                                    },
                                }}
                            >
                                Uptime (hrs)
                            </TableSortLabel>
                        </TableCell>

                        <TableCell sx={{fontSize: '0.8rem', backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#51515107' : '#f5f5f5',}} className="bg-gray-100 dark:bg-slate-800">
                            <TableSortLabel
                                active={orderBy === 'power'}
                                onClick={() => handleRequestSort('power')}
                                sx={{
                                    '& .MuiTableSortLabel-icon': {
                                        transform: orderBy === 'power' && order === 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.3s ease',
                                    },
                                }}
                            >
                                Power
                            </TableSortLabel>
                        </TableCell>

                        <TableCell sx={{fontSize: '0.8rem', backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#51515107' : '#f5f5f5',}} className="bg-gray-100 dark:bg-slate-800">
                            <TableSortLabel
                                active={orderBy === 'hostingRate'}
                                onClick={() => handleRequestSort('hostingRate')}
                                sx={{
                                    '& .MuiTableSortLabel-icon': {
                                        transform: orderBy === 'hostingRate' && order === 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.3s ease',
                                    },
                                }}
                            >
                                Hosting Rate
                            </TableSortLabel>
                        </TableCell>

                        <TableCell sx={{fontSize: '0.8rem', backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#51515107' : '#f5f5f5',}} className="bg-gray-100 dark:bg-slate-800">
                            <TableSortLabel
                                active={orderBy === 'workerEngine'}
                                onClick={() => handleRequestSort('workerEngine')}
                                sx={{
                                    '& .MuiTableSortLabel-icon': {
                                        transform: orderBy === 'workerEngine' && order === 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.3s ease',
                                    },
                                }}
                            >
                                Worker Engine
                            </TableSortLabel>
                        </TableCell>

                        <TableCell sx={{fontSize: '0.8rem', backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#51515107' : '#f5f5f5',}} className="bg-gray-100 dark:bg-slate-800">
                            <TableSortLabel
                                active={orderBy === 'spend'}
                                onClick={() => handleRequestSort('spend')}
                                sx={{
                                    '& .MuiTableSortLabel-icon': {
                                        transform: orderBy === 'spend' && order === 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.3s ease',
                                    },
                                }}
                            >
                                Expense (Coin)
                            </TableSortLabel>
                        </TableCell>

                        <TableCell sx={{ fontSize: '0.8rem', backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#51515107' : '#f5f5f5', }} className="bg-gray-100 dark:bg-slate-800">
                            <TableSortLabel
                                active={orderBy === 'spendInBtc'}
                                onClick={() => handleRequestSort('spendInBtc')}
                                sx={{
                                    '& .MuiTableSortLabel-icon': {
                                        transform: orderBy === 'spendInBtc' && order === 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.3s ease',
                                    },
                                }}
                            >
                                Expense (BTC)
                            </TableSortLabel>
                        </TableCell>

                        <TableCell sx={{fontSize: '0.8rem', backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#51515107' : '#f5f5f5',}} className="bg-gray-100 dark:bg-slate-800">
                            <TableSortLabel
                                active={orderBy === 'spendInUsd'}
                                onClick={() => handleRequestSort('spendInUsd')}
                                sx={{
                                    '& .MuiTableSortLabel-icon': {
                                        transform: orderBy === 'spendInUsd' && order === 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.3s ease',
                                    },
                                }}
                            >
                                Expense ($)
                            </TableSortLabel>
                        </TableCell>

                        <TableCell sx={{fontSize: '0.8rem', backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#51515107' : '#f5f5f5',}} className="bg-gray-100 dark:bg-slate-800">
                            <TableSortLabel
                                active={orderBy === 'totalCharge'}
                                onClick={() => handleRequestSort('totalCharge')}
                                sx={{
                                    '& .MuiTableSortLabel-icon': {
                                        transform: orderBy === 'totalCharge' && order === 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.3s ease',
                                    },
                                }}
                            >
                                Income
                            </TableSortLabel>
                        </TableCell>

                        <TableCell sx={{fontSize: '0.8rem', backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#51515107' : '#f5f5f5',}} className="bg-gray-100 dark:bg-slate-800">P/L</TableCell>
                    </TableRow>
                </TableHead>

                {isLoading ?
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={14} sx={{ textAlign: 'center' }}>
                                <TableLoader />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    :
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ fontSize: '0.8rem' }}>{row.expenseDate}</TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>{row.workerId}</TableCell>
                                <TableCell sx={{ fontSize: '0.8rem', color: row.customer === "unknown" ? "red" : "inherit" }}>{row.customer}</TableCell>
                                <TableCell sx={{ fontSize: '0.8rem', color: row.machineModelName === "unknown" ? "red" : "inherit" }}>{row.machineModelName}</TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>{row.currency}</TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>{row.uptimeInHours}</TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>{row.power}</TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>{Number(row.hostingRate).toFixed(3)}</TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>{row.workerEngine}</TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>{row.spend?.toFixed(8)}</TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>{row.spendInBtc?.toFixed(8)}</TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>{row.spendInUsd?.toFixed(2)}</TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>{Number(row.totalCharge)?.toFixed(2)}</TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>{(Number(row.totalCharge) - row.spendInUsd)?.toFixed(4)}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={10} sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Total</TableCell>
                            <TableCell sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                                <Typography variant="caption" component="div" sx={{ fontWeight: 'normal', color: 'orange' }}>
                                    Expense(Btc)
                                </Typography>
                                {Number(tableTotal.spendInBtc).toFixed(8)}</TableCell>
                            <TableCell sx={{ fontSize: '0.8rem', fontWeight: 'bold' }} >
                                <Typography variant="caption" component="div" sx={{ fontWeight: 'normal', color: 'orange' }}>
                                    Expense($)
                                </Typography>
                                {(Number(tableTotal.spendInUsd)).toFixed(2)}
                            </TableCell>
                            <TableCell sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                                <Typography variant="caption" component="div" sx={{ fontWeight: 'normal', color: 'orange' }}>
                                    income
                                </Typography>
                                {Number(tableTotal.totalCharge).toFixed(2)}
                            </TableCell>
                            <TableCell sx={{ fontSize: '0.8rem', fontWeight: 'bold', textAlign: 'center' }}>
                                <Typography variant="caption" component="div" sx={{ fontWeight: 'normal', color: 'orange' }}>
                                    P/L
                                </Typography>
                                {(Number(tableTotal.totalCharge) - (Number(tableTotal.spendInUsd))).toFixed(2)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                }
            </Table>
        </TableContainer>
    );
};

export default SortableTable;
