import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, Box, Button, SelectChangeEvent, Chip, IconButton } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CancelIcon from '@mui/icons-material/CloseRounded';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

interface SearchFilterProps {
    selectedCustomers: string[];
    setSelectedCustomers: (value: string[]) => void;
    customerDataList: { name: string }[];
    fromDate: Date | null;
    setFromDate: (date: Date | null) => void;
    toDate: Date | null;
    setToDate: (date: Date | null) => void;
    handleMultiChange: (event: SelectChangeEvent<string[]>) => void;
    handleDelete: (nameToDelete: string) => void;
    fetchData: () => void;
    selectOpen: boolean;
    setSelectOpen: (value: boolean) => void;
}

const SearchFilter: React.FC<SearchFilterProps> = ({
    selectedCustomers,
    setSelectedCustomers,
    customerDataList,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    handleMultiChange,
    handleDelete,
    fetchData,
    selectOpen,
    setSelectOpen,
}) => {
    return (
        <Grid container spacing={1} sx={{ margin: '10px', paddingX: '16px' }} justifyContent="flex-end" alignItems="center">
            <Grid item>
                <FormControl sx={{ minWidth: 200 }} size="small">
                    <InputLabel id="multi-select-label">Search Customers</InputLabel>
                    <Select
                        labelId="multi-select-label"
                        multiple
                        value={selectedCustomers}
                        onChange={handleMultiChange}
                        sx={{ minWidth: 200 }}
                        onClose={() => setSelectOpen(false)}
                        onOpen={() => setSelectOpen(true)}
                        open={selectOpen}
                        label="Search Customers"
                        renderValue={(selected) => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 0.5,
                                    whiteSpace: 'nowrap',
                                    overflowX: 'auto',
                                    scrollbarWidth: 'none',
                                    '&::-webkit-scrollbar': { display: 'none' },
                                }}
                            >
                                {selected.map((name) => (
                                    <Chip
                                        key={name}
                                        label={name}
                                        size="small"
                                        sx={{ borderRadius: 1 }}
                                        onDelete={() => handleDelete(name)}
                                        deleteIcon={
                                            <IconButton
                                                onMouseDown={(event) => event.stopPropagation()}
                                                sx={{ p: 0, borderRadius: 1 }}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        }
                                    />
                                ))}
                            </Box>
                        )}
                    >
                        {customerDataList.map((item) => (
                            <MenuItem key={item.name} value={item.name}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <Box textAlign="center">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Select From Date"
                            value={fromDate}
                            format="dd/MM/yyyy"
                            slotProps={{ textField: { size: 'small' } }}
                            onChange={(date) => setFromDate(date)}
                        />
                    </LocalizationProvider>
                </Box>
            </Grid>
            <Grid item>
                <Box textAlign="center">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Select To Date"
                            value={toDate}
                            format="dd/MM/yyyy"
                            slotProps={{ textField: { size: 'small' } }}
                            onChange={(date) => setToDate(date)}
                        />
                    </LocalizationProvider>
                </Box>
            </Grid>
            <Grid item>
                <Box textAlign="center">
                    <Button variant="contained" onClick={fetchData} size="large">
                        Search
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default SearchFilter;
