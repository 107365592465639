import React from 'react';
import { Grid, MenuItem, Select, SelectChangeEvent, Pagination, FormControl } from '@mui/material';

interface PaginationControlsProps {
    rowsPerPage: number;
    onRowsPerPageChange: (event: SelectChangeEvent<number>) => void;
    totalPages: number;
    currentPage: number;
    onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({
    rowsPerPage,
    onRowsPerPageChange,
    totalPages,
    currentPage,
    onPageChange,
}) => {
    return (
        <FormControl sx={{ marginTop: 2, width: '100%' }}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                <Grid item>
                    <p>Rows Per Page</p>
                </Grid>
                <Grid item>
                    <Select
                        value={rowsPerPage}
                        onChange={onRowsPerPageChange}
                        size="small"
                        sx={{ minWidth: 60 }}
                    >
                        <MenuItem value={200}>200</MenuItem>
                        <MenuItem value={300}>300</MenuItem>
                        <MenuItem value={500}>500</MenuItem>
                        <MenuItem value={1000}>1000</MenuItem>
                    </Select>
                </Grid>
                <Grid item sx={{ ml: 'auto' }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={onPageChange}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    />
                </Grid>
            </Grid>
        </FormControl>
    );
};

export default PaginationControls;
