import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Typography, Tab, Tabs, SelectChangeEvent } from '@mui/material';
import axios from 'axios';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import TableLoader from '../GlobalComponents/TableLoader';
import PaymentTable from './PaymentTable';
import { processTransactionList, TransactionData } from './PaymentTypes';
import PaymentHistoryTable from './PaymentHistoryTable';
import BalanceDisplay from './BalanceDisplay';
import { CustomerGroup, SelectedDataItem } from '../Machines/types';
import { API_PATH, appContext } from '../../../App';
import SearchFilter from './FilterBlock';
import Cookies from 'js-cookie';

export default function PaymentsList() {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<string>("all");
    const [rows, setRows] = useState<TransactionData[]>([]);
    const [pendingRows, setPendingRows] = useState<TransactionData[]>([]);

    const [customerDataList, setCustomerDataList] = useState<SelectedDataItem[]>([]);
    const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
    const [fromDate, setFromDate] = useState<Date | null>(new Date());
    const [toDate, setToDate] = useState<Date | null>(new Date());
    const [selectOpen, setSelectOpen] = useState(false);

    const [historyTableData, setHistoryTableData] = useState<TransactionData[]>([]);
    const { authRole } = React.useContext(appContext);

    useEffect(() => {
        fetchCustomerList();
        fetchPendingList()
        searchData('');
    }, []);

    const fetchCustomerList = () => {
        axios.get(API_PATH + '/segPoolRewardDistribution/poolusers', getAxiosConfig())
            .then(response => {
                const DataList: SelectedDataItem[] = response.data.map((item: CustomerGroup) => ({
                    id: item,
                    name: item
                }));
                setCustomerDataList(DataList);
            })
            .catch(error => {
                console.error("Error fetching customer list:", error);
            });
    };

    const fetchPendingList = () => {
        setLoading(true);
        axios.get(`/api/segPoolRewardDistribution/pending`, getAxiosConfig())
            .then(response => {
                setPendingRows(processTransactionList(response.data))
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching pending data:", error);
                setError("Failed to fetch data.");
                setLoading(false);
            });

    }

    const searchData = (status: string) => {
        setLoading(true);

        const formattedFromDate = fromDate
            ? `${fromDate.getUTCFullYear()}-${String(fromDate.getUTCMonth() + 1).padStart(2, "0")}-${String(fromDate.getUTCDate()).padStart(2, "0")}`
            : null;

        const formattedToDate = toDate
            ? `${toDate.getUTCFullYear()}-${String(toDate.getUTCMonth() + 1).padStart(2, "0")}-${String(toDate.getUTCDate()).padStart(2, "0")}`
            : null;

        axios.get(`/api/segPoolRewardDistribution/transactions?statuses=${status}&from=${formattedFromDate}&to=${formattedToDate}&users=${selectedCustomers.join(',')}`, getAxiosConfig())
            .then(response => {
                switch (activeTab) {
                    case "all":
                        setHistoryTableData(response.data);
                        break;
                    case "pending":
                        setRows(processTransactionList(response.data))
                        break;
                    case "failed":
                        setRows(processTransactionList(response.data))

                        break;
                    default:
                        setHistoryTableData(response.data);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching transaction data:", error);
                setError("Failed to fetch data.");
                setLoading(false);
            });
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };

    useEffect(() => {
        fetchData()
    }, [activeTab]);

    const handleMultiChange = (event: SelectChangeEvent<string[]>) => {
        setSelectedCustomers(event.target.value as string[]);
        setSelectOpen(false);
    };

    const handleDelete = (nameToDelete: string) => {
        setSelectedCustomers((prev) => prev.filter((name) => name !== nameToDelete));
    };

    const fetchData = () => {
        switch (activeTab) {
            case "all":
                searchData('');
                break;
            case "pending":
                fetchPendingList();
                break;
            case "failed":
                searchData("TRANSFER_FAILED");
                break;
            default:
                searchData('');
        }
    };

    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <BalanceDisplay />

            <Box>
                <Box sx={{ width: '100%', bgcolor: 'background.paper', marginBottom: "15px" }}>
                    <Tabs value={activeTab} onChange={handleChange} >
                        <Tab label="All Payments" value={"all"} />
                        {authRole === 'ADMIN' && < Tab label="Pending Payments" value={"pending"} />}
                        {authRole === 'ADMIN' && <Tab label="Failed Payments" value={"failed"} />}

                    </Tabs>
                </Box>
                {activeTab != "pending" &&
                    <SearchFilter
                        selectedCustomers={selectedCustomers}
                        setSelectedCustomers={setSelectedCustomers}
                        customerDataList={customerDataList}
                        fromDate={fromDate}
                        setFromDate={setFromDate}
                        toDate={toDate}
                        setToDate={setToDate}
                        handleMultiChange={handleMultiChange}
                        handleDelete={handleDelete}
                        fetchData={fetchData}
                        selectOpen={selectOpen}
                        setSelectOpen={setSelectOpen}
                    />
                }
                {loading ? (
                    <TableLoader />
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : activeTab === "pending" ? (
                    rows && <PaymentTable rows={pendingRows} refresh={fetchPendingList} />
                ) : activeTab === "failed" ? (
                    rows && <PaymentTable rows={rows} refresh={fetchData} />
                ) : (
                    <PaymentHistoryTable data={historyTableData} />
                )}
            </Box>
        </Box>
    );
}
