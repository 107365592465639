import * as React from 'react';
import { useState, useContext, useMemo } from 'react';
import { createTheme } from '@mui/material/styles';
import DescriptionIcon from '@mui/icons-material/Description';
import GroupIcon from '@mui/icons-material/Group';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import type { Router, Navigation, Session, } from '@toolpad/core';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import { Analytics, OfflineBoltOutlined, PieChart, Settings } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import StorageIcon from '@mui/icons-material/Storage';
import { appContext } from '../App';
import { CustomNotifications } from './Components/GlobalComponents/Nofications';
import { signOut } from '../helpers/AuthHelper';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import ApiIcon from '@mui/icons-material/Api';
import Cookies from 'js-cookie';

interface NotificationModalProps {
    open: boolean;
    onClose: () => void;
}

const ADMIN_NAVIGATION: Navigation = [
    {
        segment: 'customers',
        title: 'Customers',
        icon: <GroupIcon />,
    },
    {
        segment: 'machines',
        title: 'Machines',
        icon: <StorageIcon />,
    },
    {
        segment: 'invoices',
        title: 'Invoices',
        icon: <DescriptionIcon />,
    },
    {
        segment: 'monitor',
        title: 'Hash Monitor',
        icon: <MonitorHeartIcon />,
    },
    {
        segment: 'payments',
        title: 'Payments',
        icon: <LocalAtmIcon />,
    },
    {
        segment: 'report',
        title: 'Financial Reports',
        icon: <Analytics />,
    },
    {
        segment: 'simulator',
        title: 'Simulator',
        icon: <PieChart />,
    },
    {
        kind: 'divider',
    },
    {
        segment: 'apiCalls',
        title: 'API',
        icon: <ApiIcon />,
    },
    {
        kind: 'divider',
    },
    {
        segment: 'settings',
        title: 'Settings',
        icon: <Settings />,
        children: [
            {
                segment: 'machines',
                title: 'Machines Model',
                icon: <MicrowaveIcon />,
            },
            {
                segment: 'authentication',
                title: 'Authentication',
                icon: <DomainVerificationIcon />,
            },
            {
                segment: 'nicehash',
                title: 'Nicehash',
                icon: <OfflineBoltOutlined />,
            },
        ],
    },
];

const READONLY_NAVIGATION: Navigation = [
    {
        segment: 'customers',
        title: 'Customers',
        icon: <GroupIcon />,
    },
    {
        segment: 'machines',
        title: 'Machines',
        icon: <StorageIcon />,
    },
    {
        segment: 'invoices',
        title: 'Invoices',
        icon: <DescriptionIcon />,
    },
    {
        segment: 'payments',
        title: 'Payments',
        icon: <LocalAtmIcon />,
    },
    {
        segment: 'report',
        title: 'Financial Reports',
        icon: <Analytics />,
    },
    {
        segment: 'simulator',
        title: 'Simulator',
        icon: <PieChart />,
    },
    {
        kind: 'divider',
    },
    {
        segment: 'settings',
        title: 'Settings',
        icon: <Settings />,
        children: [
            {
                segment: 'machines',
                title: 'Machines Model',
                icon: <MicrowaveIcon />,
            }
        ],
    },
];

const demoTheme = createTheme({
    cssVariables: {
        colorSchemeSelector: 'data-toolpad-color-scheme',
    },
    colorSchemes: { light: true, dark: true },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 600,
            lg: 1200,
            xl: 1536,
        },
    },
});

interface DashboardLayoutBasicProps {
    children?: React.ReactNode;
    window?: () => Window;
}

export default function DashboardLayoutBasic(props: DashboardLayoutBasicProps) {
    const { children, window } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const { isAuthVerified, setIsAuthVerified, authUserName, authRole } = useContext(appContext);


    const [pathname, setPathname] = useState(location.pathname);

    const [session, setSession] = useState<Session | null>({
        user: {
            name: authUserName,
            // image: '',
        },
    });

    const authentication = useMemo(() => {
        return {
            signIn: () => { },
            signOut: () => {
                signOut()
                setIsAuthVerified((x: boolean) => !x)
            },
        };
    }, []);

    React.useEffect(() => {
        navigate(pathname);
    }, [pathname]);

    React.useEffect(() => {
        setPathname(location.pathname);
    }, [location.pathname]);

    const router = React.useMemo<Router>(() => ({
        pathname,
        searchParams: new URLSearchParams(),
        navigate: (path) => setPathname(String(path)),
    }), [pathname]);

    React.useEffect(() => {
        navigate(pathname)
    }, [pathname])

    const demoWindow = window !== undefined ? window() : undefined;



    return (
        <AppProvider
            session={session}
            navigation={authRole === 'ADMIN' ? ADMIN_NAVIGATION : READONLY_NAVIGATION}
            router={router}
            theme={demoTheme}
            window={demoWindow}
            authentication={authentication}
            branding={{
                logo: <img src="https://mui.com/static/logo.png" alt="MUI logo" />,
                title: 'MY DASHBOARD',
            }}
        >
            <DashboardLayout>
                <React.Suspense fallback={<div>Loading...</div>}>
                    {children}
                </React.Suspense>
                <CustomNotifications />
            </DashboardLayout>
        </AppProvider>
    );
}
