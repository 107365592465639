import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Typography, SvgIcon, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { RefreshRounded, Wallet } from '@mui/icons-material';
import BalanceLoader from '../GlobalComponents/BalanceLoader';
import { BalanceData } from './PaymentTypes';
import axios from 'axios';
import { getAxiosConfig } from '../../../helpers/AuthHelper';

const BalanceDisplay = () => {

    const [balance, setBalance] = useState<BalanceData | null>(null);
    const [balanceLoading, setBalanceLoading] = useState<boolean>(true);

    useEffect(() => {
        fetchBalance();
    }, []);

    const fetchBalance = () => {
        setBalanceLoading(true);
        axios.get('/api/segPoolRewardDistribution/balance', getAxiosConfig())
            .then(response => {
                if (isObjectOfBalance(response.data)) {
                    setBalance(response.data);
                }
                setBalanceLoading(false);
            })
            .catch(error => {
                console.error("Error fetching balance:", error);
                setBalanceLoading(false);
            });
    };

    function isObjectOfBalance(obj: any) {
        if (typeof obj !== "object" || obj === null) return false;

        for (const key in obj) {
            const value = obj[key];
            if (
                typeof value !== "object" ||
                typeof value.available !== "number" ||
                typeof value.pending !== "number" ||
                typeof value.total !== "number"
            ) {
                return false;
            }
        }
        return true;
    }

    return (
        <Box sx={{ width: '100%', padding: '16px' }}>
            <Grid container spacing={2} alignItems="center" justifyContent="start">
                <Grid item xs={12} sm={2} md={1} display="flex" justifyContent="center">
                    <Button variant="contained" sx={{ padding: '16px' }} onClick={fetchBalance}>
                        <RefreshRounded />
                    </Button>
                </Grid>
                {balanceLoading ? (
                    <Grid item xs={11}>
                        <BalanceLoader />
                    </Grid>
                ) : (
                    <>
                        {balance && typeof balance === 'object' && !Array.isArray(balance) ? (
                            Object.entries(balance).map(([coin, data]) => (
                                <Grid item xs={12} sm={3} key={coin}>
                                    <Box
                                        sx={{
                                            backgroundColor: '#f9f9f9',
                                            padding: '16px',
                                            borderRadius: '8px',
                                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: '#555555',
                                                fontWeight: 'bold',
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: '2px',
                                            }}
                                            variant="body1"
                                        >
                                            <SvgIcon sx={{ marginRight: '8px', fontSize: '1.2rem', color: '#555555' }}>
                                                <Wallet />
                                            </SvgIcon>

                                            {coin} - BALANCE
                                        </Typography>
                                        <Box sx={{ textAlign: 'start' }}>
                                            <TableContainer component={Paper}>
                                                <Table size="small">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ color: '#666666', fontSize: '0.9rem', padding: '4px' }}>Available</TableCell>
                                                            <TableCell sx={{ color: '#1977d3', fontWeight: 'bold', fontSize: '0.9rem', padding: '4px' }}>
                                                                {data.available.toFixed(8)}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ color: '#666666', fontSize: '0.9rem', padding: '4px' }}>Pending</TableCell>
                                                            <TableCell sx={{ color: '#1977d3', fontWeight: 'bold', fontSize: '0.9rem', padding: '4px' }}>
                                                                {data.pending.toFixed(8)}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ color: '#666666', fontSize: '0.9rem', padding: '4px' }}>Total</TableCell>
                                                            <TableCell sx={{ color: '#43a047', fontWeight: 'bold', fontSize: '0.9rem', padding: '4px' }}>
                                                                {data.total.toFixed(8)}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))
                        ) : (
                            <div>No balance data available</div>
                        )}
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default BalanceDisplay;
