import React, { useEffect, useState } from 'react';
import LoginPage from './pages/LoginPage';
import Cookies from 'js-cookie';
import axios from 'axios';
import { authLogin, getAxiosConfig, setupAxios } from './helpers/AuthHelper';
import LoadingScreen from './pages/LoadingScreen';
import MainView from './pages/MainView';
import OrderExpenseReport from './pages/Components/OrderExpenseReport/OrderExpenseReport';
import { useLocation } from 'react-router-dom';



export const appContext = React.createContext<any | null>(null);

export const API_PATH = "/api"

function App() {
  const [isAuthVerified, setIsAuthverified] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  const [authUser, setAuthUser] = useState<string>('');
  const [authRole, setAuthRole] = useState<string>('');

  const { pathname } = useLocation();
  const match = pathname.match(/^\/expensereport\/([^/]+)$/);

  var timer: any = 0;

  useEffect(() => {
    let token = Cookies.get('authinfo');
    if (token !== undefined && token.length > 0) {
      setupAxios(token)
      setAuthToken(token)
    }
    setIsLoading(false);
    axiosInterceptor()
  }, []);

  useEffect(() => {
    setupAxios(authToken)
    verifyToken();
  }, [authToken]);

  function axiosInterceptor() {
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (error.code == "ERR_BAD_REQUEST" && (error.config.url != "/auth/info/")) {
        verifyTokenQueue()
      }
      return Promise.reject(error);
    });
  }
  function verifyTokenQueue() {
    clearTimeout(timer);
    timer = setTimeout(verifyToken, 200);
  }
  function verifyToken() {
    axios.post('/auth/info/', {}, getAxiosConfig())
      .then(r => {
        setIsAuthverified(true);
        setAuthUser(r.data.user);
        setAuthRole(r.data.userRole);
      })
      .catch(e => { setAuthToken("") })
  }

  function doLogin(username: string, password: string) {
    setIsLoading(true)
    authLogin(username, password, (token) => {
      setAuthToken(token)
      setIsAuthverified(true);
      setIsLoading(false)
      window.location.reload();
    }, () => {
      setIsLoading(false)
    })

  }

  if (match) {
    const reportId = match[1];
    return <OrderExpenseReport reportId={reportId} />;
  }

  if ((!isLoading) && (authToken === undefined || authToken.length === 0)) {
    return <LoginPage doLogin={doLogin} />
  }
  if (!isAuthVerified || isLoading) {
    return <LoadingScreen />
  }

  return <MainView validate={verifyToken} user={authUser} authRole={authRole}/>;
}

export default App;
