import React, { SetStateAction, useEffect, useState } from "react";
import { Button, Box, Typography, Container } from "@mui/material";
import { Download, Search } from "@mui/icons-material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import axios from "axios";
import OrderTable from "./Table";

interface OrderExpenseReportProps {
    reportId: string;
}

type Order = {
    id: string;
    date: string;
    orderId: string;
    groupId: string;
    username: string;
    port: string;
    hashRate: number;
    rejectionRate: number;
    totalExpense: number;
    amount: number;
};

const OrderExpenseReport: React.FC<OrderExpenseReportProps> = ({ reportId }) => {
    const [fromDate, setFromDate] = React.useState(dayjs().startOf('day'));
    const [toDate, setToDate] = React.useState(dayjs().startOf('day'));
    const [row, setRow] = useState<Order[]>([]);
    const [loader, setLoader] = useState(true);


    const formatDate = (date: Date | Dayjs | null): number | null => {
        if (date) {
            return new Date(dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSS') + "z").getTime();
        }
        return null;
    };

    const handleDateChange = (setDate: React.Dispatch<SetStateAction<Dayjs>>, value: Dayjs | null) => {
        if (value) {
            setDate(value);
        } else {
            setDate(dayjs().startOf('day'));
        }
    };


    useEffect(() => {
        fetchReport();
    }, [reportId]);

    const fetchReport = () => {
        setLoader(true);
        axios.get(`/public/orderExpense/report?code=${reportId}&from=${formatDate(fromDate)}&to=${formatDate(toDate)}`)
            .then(response => {
                setRow(response.data);
                setLoader(false);
            })
            .catch(error => {
                console.error("Error fetching Report:", error);
                setLoader(false);
            });
    };

    const downloadCSV = () => {
        const csvHeader = 'Date,Order ID,Name,Port,Hash Rate,Rejection Rate,Total Expense';

        const totalExpense = row.reduce((acc, curr) => acc + (curr.amount || 0), 0);
        const amountToPaid = totalExpense + totalExpense * 0.045;

        const csvRows = [
            csvHeader,
            ...row.map((rowData) =>
                [
                    (rowData.date).split('T')[0],
                    rowData.orderId,
                    rowData.username,
                    rowData.port,
                    rowData.hashRate.toFixed(2),
                    rowData.rejectionRate.toFixed(2),
                    rowData.amount.toFixed(8)
                ]
                    .map((value) => (typeof value === 'string' ? `"${value}"` : value))
                    .join(',')
            ),
            `,,,"Cost to Segments:",,,,,,${totalExpense.toFixed(8)}`,
            `,,,"Amount to Paid (+4.5%):",,,,,,${amountToPaid.toFixed(8)}`,
        ];
        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `worker_data_${formatDate(dayjs())}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
    };

    return (
        <Container maxWidth="xl">
            <Box
                gap={1}
                sx={{
                    my: 2,
                    backgroundColor: '#d1e8ff',
                    padding: { xs: 1, sm: 1.5 },
                    borderRadius: 1,
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        fontFamily: 'monospace',
                        ml: { xs: 0, sm: '1rem' },
                        color: '#1565c0',
                        textAlign: 'center',
                        width: '100%',
                    }}
                    gutterBottom
                >
                    Order Expense Report
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', mb: '2rem' }}>
                <Box sx={{ display: 'flex', justifyContent: "left", alignItems: 'center', gap: '8px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="From Date & Time"
                            value={fromDate}
                            onChange={(newValue) => handleDateChange(setFromDate, newValue)}
                            views={['day', 'month', 'year', 'hours']}
                            format="DD-MM-YYYY HH:mm"
                        />
                        <DateTimePicker
                            label="To Date & Time"
                            value={toDate}
                            onChange={(newValue) => handleDateChange(setToDate, newValue)}
                            views={['day', 'month', 'year', 'hours']}
                            format="DD-MM-YYYY HH:mm"
                        />
                    </LocalizationProvider>
                    <Button variant="contained" size="small" sx={{ paddingY: 1 }} onClick={fetchReport}>
                        <Search />
                    </Button>
                </Box>
                <Button variant="contained" size="small" sx={{ paddingY: 1 }} onClick={downloadCSV}>
                    <Download />
                </Button>
            </Box>
            <OrderTable row={row} loader={loader} />
        </Container>
    );
};

export default OrderExpenseReport;
